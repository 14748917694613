import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import startCase from 'lodash/startCase'
import Dropzone from 'vue2-dropzone'
import { Quill, quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module'
import Viewer from 'v-viewer/src/component.vue'
import { Prop } from 'vue-property-decorator'

// Base Page
import BasePage from '@/pages/Base'

// Services
import ProductService from '@/services/Products'
import Categories from '@/services/Categories' 
import Rules from '@/services/Rules' 

// Configs
import config from '@/configs'

// Global Constant Variables
const storage = window.localStorage
const baseUrl = config.api.baseURL
Quill.register('modules/imageResize', ImageResize)

@Component({ components: { Dropzone, quillEditor } })
export default class ManageCategoryIndex extends BasePage {
  constructor() {
    super()
  }

    // BaseURL
    baseUrl = baseUrl

    // Filter
    filterLoading: boolean = false
    filterLabel: string = 'Filter by Status'
    filterHint: string = 'Apply Status Filters Here'
    filterItems: any = {
      items: []
    }
    filterIcon: string = 'mdi-filter-variant'
    filterSelected: string = ''

    // Search
    searchLoading: boolean = false
    searchInput: string = ''
    searchLabel: string = 'Search here...'
    searchHint: string = 'Search Anything Here'
    searchIcon: string = 'search'
    
    // Table
    expanded = []
    tableLoadingList: boolean = true
    tableTotalItemsList: number | string = 0
    tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
    tablePagination: any = {
      sortBy: 'created_at',
      page: 1,
      rowsPerPage: 25,
      descending: false,
      totalItems: 0
    }
    tableHeaders: object[] = [
      {
        text: 'ID',
        align: 'left',
        sortable: false,
        value: '-id'
      },
      {
        text: 'Image',
        align: 'center',
        sortable: false,
        value: 'image'
      },
      {
        text: 'Category Name',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Priority',
        align: 'center',
        sortable: true,
        value: 'priority'
      },
      {
        text: 'Total Products',
        align: 'center',
        sortable: false,
        value: 'totalProducts'
      },
      {
        text: 'Total Products Uncategorized',
        align: 'center',
        sortable: false,
        value: 'totalProductsUncategorized'
      },
      {
        text: 'Category Rule',
        align: 'center',
        sortable: true,
        value: 'category_rule_description'
      },
      {
        text: 'Action',
        align: 'center',
        sortable: false,
        value: 'action'
      }
    ]

    // Get Categories
    topCategoriesList: any = []
    categoriesLevel2List: any = []
    categoriesLevel3List: any = []
    opts: any = {
      params: {
        'filter[level][is]': '1',
        'page[limit]' : '100',
        'include': 'child, rules'
      }
    }

    // Data Category
    dataTopCategory: any = {
      items: []
    }
    dataCategoryLevel2: any ={
      items: []
    }
    topCategory: number = null
    categoryLevel2: number = null
    categoryLevel3: number = null
    // topCategories: any = []
    categoriesLevel2: any = []
    categoriesLevel3: any = []

    loadingCategories: boolean = false
    loadingLevel2Categories: boolean = false
    loadingLevel3Categories: boolean = false

    noLevel2Categories: boolean = false
    noLevel3Categories: boolean = false

    // Add New Category
    addNewCategoryDialog: boolean = false
    categoryName: string = ''
    categoryPriority: number = null
    categoryRules: any = []

    selectedLevelCategory: number = null
    payload: any = []

    // Delete Category
    categoryDeleteModal: boolean = false
    categoryIdDelete: number = null

    // Label Add Category
    labelAddCategory: any = [
      {
        text: 'Add Category Level 1',
        value: 1
      },
      {
        text: 'Add Category Level 2',
        value: 2
      },
      {
        text: 'Add Category Level 3',
        value: 3
      }
    ]

    newCategoryId: number = null

    // Token
    token: string = storage.getItem('access_token')

    // Images
    options: any = {
      url: 'https://httpbin.org/post', // dummy post data. this will change later after adding an image
      headers: {
        Authorization: 'Bearer ' + this.token,
        Identifier: 'manage'
      },
      addRemoveLinks: true,
      dictDefaultMessage: 'Click Here or Drop Image Here To Upload',
      autoProcessQueue: false,
      acceptedFileTypes: 'image/*',
      maxNumberOfFiles: 1,
      maxFileSizeInMB: 2,
      duplicateCheck: true,
      thumbnailHeight: 250,
      thumbnailWidth: 250,
      paramName: 'image'
    }

    @Watch('inputTopCategory', { deep: true })
    onChangedTopCategory(to: string, from: string) {
      this.getNextCategories(to, true)
    }

    @Watch('newData.parent_id', { deep: true })
    onChangedTopCategoryEdit(to: string, from: string) {
      this.getNextCategories(to, true)
    }

    // Searh
    @Watch('searchInput', { deep: true })
    async onChangedSearchInput() {
      this.$vuetify.goTo(0)
      await this.getCategoryList()
    }

    // Category List on Table
    @Watch('inputTopCategoryList', { deep: true })
    onChangedTopCategoryList(to: string, from: string) {
      this.getNextCategoriesList(to, true)
    }
    @Watch('inputCategoryLevel2List', { deep: true })
    onChangedChildCategoryList(to: string, from: string) {
      this.getNextCategoriesList(to)
    }

    async mounted() {
      this.getCategories()
      this.getCategoryRules()
      await this.getCategoryList()
    }

    // Category List in Table
    inputTopCategoryList: number = null
    showLevel3: boolean = false
    inputCategoryLevel2List: number = null

    getDataLevel2List(data) {
      this.showLevel3 = false
      this.inputTopCategoryList = data.item.categoryId
      this.categoriesLevel2List = []
    }
    
    getDataLevel3List(id){
      this.showLevel3 = true
      this.inputCategoryLevel2List = id
      this.categoriesLevel3List = []
    }

    async getTotalProducts(id, props) {
      const index = props.index
      try {
        if(props.item.level === 1) {

          if(id != 'null') {
            this.topCategoriesList[index].loadingTotalProducts = true

            const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(id)
            const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data

            this.topCategoriesList[index].totalProductsCategory = dataResponseGetTotalProductsCategory.total_products      
          } else {
            this.topCategoriesList[index].loadingTotalProducts = true
            const opts: any = {
              params: {
                'page[num]': this.tablePagination.page,
                'page[limit]': this.tablePagination.rowsPerPage,
                sort: this.tablePagination.descending ? '-id' : 'id',
                include: 'categories,supplier,source,variations,images,country',
                uncategorized: true
              }
            }
            const response = await ProductService.getProductsLists(opts)
            this.topCategoriesList[index].totalProductsCategory = response.meta.pagination.total
          }
        } else if (props.item.level === 2) {
          this.categoriesLevel2List[index].loadingTotalProducts = true

          const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(id)
          const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data

          this.categoriesLevel2List[index].totalProductsCategory = dataResponseGetTotalProductsCategory.total_products
        } else if (props.item.level === 3) {
          this.categoriesLevel3List[index].loadingTotalProducts = true

          const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(id)
          const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data

          this.categoriesLevel3List[index].totalProductsCategory = dataResponseGetTotalProductsCategory.total_products
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        if(props.item.level === 1) {
          this.topCategoriesList[index].loadingTotalProducts = false
        } else if (props.item.level === 2) {
          this.categoriesLevel2List[index].loadingTotalProducts = false
        } else if (props.item.level === 3) {
          this.categoriesLevel3List[index].loadingTotalProducts = false
        }
      }
    }

    async getTotalUncategorizedProducts(id, props) {
      const index = props.index
      try {
        if(props.item.level === 1) {
          if(id != 'null') {
            this.topCategoriesList[index].loadingTotalUncategorizedProducts = true
                
            const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(id)
            const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data
                    
            this.topCategoriesList[index].totalProductsUncategorized = dataResponseGetTotalProductsCategory.total_uncategorized_products
          } else {
            this.topCategoriesList[index].loadingTotalUncategorizedProducts = true
            this.topCategoriesList[index].totalProductsUncategorized = 0
          }
                
        } else if(props.item.level === 2) {
          this.categoriesLevel2List[index].loadingTotalUncategorizedProducts = true

          const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(id)
          const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data
                
          this.categoriesLevel2List[index].totalProductsUncategorized = dataResponseGetTotalProductsCategory.total_uncategorized_products
        } else if(props.item.level === 3) {
          this.categoriesLevel3List[index].loadingTotalUncategorizedProducts = true

          const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(id)
          const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data
                
          this.categoriesLevel3List[index].totalProductsUncategorized = dataResponseGetTotalProductsCategory.total_uncategorized_products
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        if(props.item.level === 1) {
          this.topCategoriesList[index].loadingTotalUncategorizedProducts = false
        } else if (props.item.level === 2) {
          this.categoriesLevel2List[index].loadingTotalUncategorizedProducts = false
        } else if (props.item.level === 3) {
          this.categoriesLevel3List[index].loadingTotalUncategorizedProducts = false
        }
      }
    }

    loadingTotalProducts: boolean = false

    async getCategoryList() {
      this.topCategoriesList = []
      try {
        this.topCategoriesList = []
        this.loadingCategories = true
        const opts: any = {
          params: {
            'filter[level][is]' : '1',
            'page[limit]' : '100000',
            include : 'child,rules'
            // 'sort': this.tablePagination.sortBy,
          }
        }

        if (this.searchInput != '') {
          opts.params = {
            ...opts.params,
            'filter[name][like]': this.searchInput
          }
        } else {
          var textFilter = 'filter[name][like]'
          delete opts.params[textFilter]
        }

        this.tableLoadingList = true
        this.topCategoriesList = []
        const responseCategories = await ProductService.getTopLevelCategories(opts)
        this.topCategoriesList = []
        forEach(responseCategories.data.data, category => {
          let categoryMap = {
            name: category.attributes.name,
            categoryId: category.attributes.id,
            categoryRuleId: [],
            category_rule_description: [],
            totalProductsCategory: null,
            totalProductsUncategorized: null,
            linkProductsCategory: '',
            linkProductsCategoryUncategorized: '',
            loadingTotalProducts: false,
            loadingTotalUncategorizedProducts: false,
            level: 1,
            image: category.attributes.image,
            priority: category.attributes.priority
          }
                
          if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
            categoryMap.linkProductsCategory = 'http://managedev.asiacommerce.net/products?categoryId=' + categoryMap.categoryId
          } else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
            categoryMap.linkProductsCategory = 'http://manage.asiacommerce.net/products?categoryId=' + categoryMap.categoryId
          }

          if (categoryMap.priority === null) {
            categoryMap.priority = 0
          }

          if ( category.relationships.rules.length != 0 ) {
            forEach(category.relationships.rules, async categoryRule => {
              const responseGetCategories = await Rules.getCategoryRulesById(categoryRule.id)
              const dataresponseGetCategories = responseGetCategories.data.data

              categoryMap.categoryRuleId.push(categoryRule.id)
              categoryMap.category_rule_description.push(dataresponseGetCategories.attributes.description)
            })
          } else {
            categoryMap.category_rule_description.push('-')
          }
          this.topCategoriesList.push(categoryMap)
        })
            
        let uncategorized = {
          name: 'Uncategorized',
          categoryId: null,
          categoryRuleId: [],
          category_rule_description: '-',
          totalProductsCategory: null,
          totalProductsUncategorized: '-',
          linkProductsCategory: '',
          linkProductsCategoryUncategorized: '',
          loadingTotalProducts: false,
          loadingTotalUncategorizedProducts: false,
          level: 1,
          image: null,
          priority: 0
        }
            
        this.topCategoriesList.push(uncategorized)

        forEach(this.topCategoriesList, async list => {
          if (list.categoryId != null) {
            // const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(list.categoryId)
            // const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data

            if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
              list.linkProductsCategoryUncategorized = 'http://managedev.asiacommerce.net/products?categoryIdUncategorized=' + list.categoryId
            } else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
              list.linkProductsCategoryUncategorized = 'http://manage.asiacommerce.net/products?categoryIdUncategorized=' + list.categoryId
            }

            // list.totalProductsCategory = dataResponseGetTotalProductsCategory.total_products
            // list.totalProductsUncategorized = dataResponseGetTotalProductsCategory.total_uncategorized_products
          } else {
            // const response = await ProductService.getProductsLists(opts)
            // list.totalProductsCategory = response.meta.pagination.total

            if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
              list.linkProductsCategory = 'http://managedev.asiacommerce.net/products?categoryId=' + list.categoryId
            } else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
              list.linkProductsCategory = 'http://manage.asiacommerce.net/products?categoryId=' + list.categoryId
            }
                    
          }
        })
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoadingList = false
      }
    }

    sort_temp: string = ''

    editParentId(newData) {
      this.newData.inputCategoryLevel2 = null
    }

    async getNextCategoriesList(categoryId, inputTopCategoryList = false) {
      this.loadingCategories = true
      const opts: any = {
        params: {
          include : 'rules',
          sort: (this.tablePagination.ascending ? '-' : '') + this.tablePagination.sortBy,
          'page[limit]' : '100'
        }
      }
      try {
        let parentId = categoryId

        if (inputTopCategoryList) {
          this.loadingLevel2Categories = true
          this.noLevel2Categories = false
          this.noLevel3Categories = false
          this.categoriesLevel2 = []
          this.categoriesLevel3 = []
        } else {
          this.loadingLevel3Categories = true
          this.noLevel3Categories = false
          this.categoriesLevel3 = []
        }

        // Handling Sort
        if(opts.params.sort === 'null') {
          opts.params = {
            ...opts.params,
            sort : this.sort_temp
          }
        } else {
          this.sort_temp = opts.params.sort
        }

        const responseData = await ProductService.getLowLevelCategories(parentId, opts)

        if (responseData.data.data.length > 0) {
                
          //assign response data to global object
          forEach(responseData.data.data, category => {
            if ( category.relationships.rules.length != 0 ) {
              const dataCategoryMap: any = {
                name: category.attributes.name,
                value: category.attributes.id,
                categoryRuleId: [],
                category_rule_description: [],
                totalProductsCategory: null,
                totalProductsUncategorized: null,
                linkProductsCategory: '',
                linkProductsCategoryUncategorized: '',
                loadingTotalProducts: false,
                loadingTotalUncategorizedProducts: false,
                level: category.attributes.level,
                image: category.attributes.image,
                priority: category.attributes.priority
              }

              if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
                dataCategoryMap.linkProductsCategory = 'http://managedev.asiacommerce.net/products?categoryId=' + dataCategoryMap.value
              } else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
                dataCategoryMap.linkProductsCategory = 'http://manage.asiacommerce.net/products?categoryId=' + dataCategoryMap.value
              }

              if (dataCategoryMap.priority === null) {
                dataCategoryMap.priority = 0
              }
        
              forEach(category.relationships.rules, async categoryRule => {
                const responseGetCategories = await Rules.getCategoryRulesById(categoryRule.id)
                const dataresponseGetCategories = responseGetCategories.data.data

                dataCategoryMap.categoryRuleId.push(categoryRule.id)
                dataCategoryMap.category_rule_description.push(dataresponseGetCategories.attributes.description)
              })
              if (inputTopCategoryList) {
                this.categoriesLevel2List.push(dataCategoryMap)
                this.loadingLevel2Categories = false
                forEach(this.categoriesLevel2List, async list => {
                  list.level = 2
                  // const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(list.value)
                  // const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data

                  if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
                    list.linkProductsCategoryUncategorized = 'http://managedev.asiacommerce.net/products?categoryIdUncategorized=' + list.value
                  } else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
                    list.linkProductsCategoryUncategorized = 'http://manage.asiacommerce.net/products?categoryIdUncategorized=' + list.value
                  }
                                
                  // list.totalProductsCategory = dataResponseGetTotalProductsCategory.total_products
                  // list.totalProductsUncategorized = dataResponseGetTotalProductsCategory.total_uncategorized_products
                                
                }) 
              } else {
                this.categoriesLevel3List.push(dataCategoryMap)
                this.loadingLevel3Categories = false
                forEach(this.categoriesLevel3List, async list => {
                  list.level = 3
                  // const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(list.value)
                  // const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data

                  if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
                    list.linkProductsCategoryUncategorized = 'http://managedev.asiacommerce.net/products?categoryIdUncategorized=' + list.value
                  } else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
                    list.linkProductsCategoryUncategorized = 'http://manage.asiacommerce.net/products?categoryIdUncategorized=' + list.value
                  }
                                
                  // list.totalProductsCategory = dataResponseGetTotalProductsCategory.total_products
                  // list.totalProductsUncategorized = dataResponseGetTotalProductsCategory.total_uncategorized_products
                })
              }
            } else {
              const dataCategoryMap: any = {
                name: category.attributes.name,
                value: category.attributes.id,
                categoryRuleId: [],
                category_rule_description: '-',
                totalProductsCategory: null,
                totalProductsUncategorized: null,
                linkProductsCategory: '',
                linkProductsCategoryUncategorized: '',
                loadingTotalProducts: false,
                loadingTotalUncategorizedProducts: false,
                level: category.attributes.level,
                image: category.attributes.image,
                priority: category.attributes.priority
              }
                        
              if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
                dataCategoryMap.linkProductsCategory = 'http://managedev.asiacommerce.net/products?categoryId=' + dataCategoryMap.value
              } else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
                dataCategoryMap.linkProductsCategory = 'http://manage.asiacommerce.net/products?categoryId=' + dataCategoryMap.value
              }

              if (dataCategoryMap.priority === null) {
                dataCategoryMap.priority = 0
              }
                        
              if (inputTopCategoryList) {
                this.categoriesLevel2List.push(dataCategoryMap)
                this.loadingLevel2Categories = false

                forEach(this.categoriesLevel2List, async list => {
                  list.level = 2
                  // const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(list.value)
                  // const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data

                  if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
                    list.linkProductsCategoryUncategorized = 'http://managedev.asiacommerce.net/products?categoryIdUncategorized=' + list.value
                  } else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
                    list.linkProductsCategoryUncategorized = 'http://manage.asiacommerce.net/products?categoryIdUncategorized=' + list.value
                  }
                                
                  // list.totalProductsCategory = dataResponseGetTotalProductsCategory.total_products
                  // list.totalProductsUncategorized = dataResponseGetTotalProductsCategory.total_uncategorized_products
                })
              } else {
                this.categoriesLevel3List.push(dataCategoryMap)
                this.loadingLevel3Categories = false
                forEach(this.categoriesLevel3List, async list => {
                  list.level = 3
                  // const responseGetTotalProductsCategory = await Categories.getTotalProductsCategories(list.value)
                  // const dataResponseGetTotalProductsCategory = responseGetTotalProductsCategory.data

                  if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
                    list.linkProductsCategoryUncategorized = 'http://managedev.asiacommerce.net/products?categoryIdUncategorized=' + list.value
                  } else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
                    list.linkProductsCategoryUncategorized = 'http://manage.asiacommerce.net/products?categoryIdUncategorized=' + list.value
                  }
                                
                  // list.totalProductsCategory = dataResponseGetTotalProductsCategory.total_products
                  // list.totalProductsUncategorized = dataResponseGetTotalProductsCategory.total_uncategorized_products
                })
              }
            }
          })
        } else {
          //No category dimmers if no data
          if (inputTopCategoryList) {
            this.loadingLevel2Categories = false
            this.noLevel2Categories = true
          } else {
            this.loadingLevel3Categories = false
            this.noLevel3Categories = true
          }
        }
      } catch (error) {
        this.catchHandler(error)
      }
    }

    // Add New Category
    dataCategoryRulesFinal: any = []

    addNewCategoryButton() {
      this.categoryRules = []
      this.addNewCategoryDialog = true
      this.selectedLevelCategory = null
      this.inputTopCategory = null
      this.inputCategoryLevel2 = null
      this.categoryName = null
      this.categoryPriority = null
      this.dataCategoryRulesFinal = []
      this.getCategories()
    }

    cancelAddNewCategoryDialog() {
      this.addNewCategoryDialog = false
    }

    // Images
    fileImages: any = []
    
    imageAdded(file) { 
      this.fileImages = file
    }
    imageAddedError(file) {
      this.error()

      if (file.type != 'image/jpg' && file.type != 'image/jpeg' && file.type != 'image/png') {
        ; (this.$refs as any).imageCategory.removeFile(file)
        this.showSnackbar({ text: 'Error! Not an image file(color: s)', timeout: '' })
      } else if (file.size > this.options.maxFileSizeInMB * 1024 * 1024) {
        ; (this.$refs as any).imageCategory.removeFile(file)
        this.showSnackbar({
          text: `Error! Image(s) size is too large. Max ${this.options.maxFileSizeInMB} MB`,
          color: 'red',
          timeout: 5000
        })
      } else if ((this.$refs as any).imageCategory.getQueuedFiles().length >= this.options.maxNumberOfFiles) {
        ; (this.$refs as any).imageCategory.removeFile(file)
        this.showSnackbar({
          text: `Error! Image(s) too much. Max ${this.options.maxNumberOfFiles} Images`,
          color: 'red',
          timeout: 5000
        })
      }
    }
    imageDuplicate(file) {
      this.showSnackbar({ text: 'Image is already added', color: 'orange', timeout: 2000 })
    }
    beforeSendingImage(file, xhr, formData) {
      //   this.showSnackbar({ text: 'Before Sending', color: 'orange', timeout: 2000 })
      formData.append('type', 'image')
    }
    beforeSendingImageMultiple(file, xhr, formData) {
      formData.append('type', 'image')
    }
    imageUploadSuccess(file, response) {
      this.showSnackbar({ text: 'Image Upload Success', color: 'orange', timeout: 2000 })
      if ((this.$refs as any).imageCategory.getUploadingFiles().length <= 0) this.done()
      ; (this.$refs as any).imageCategory.removeFile(file)
    }
    imageUploadMultipleSuccess(files, response) {
      this.showSnackbar({ text: 'Image Multiple Upload Success', color: 'orange', timeout: 2000 })
      if ((this.$refs as any).imageCategory.getUploadingFiles().length <= 0) this.done()
    }
    imageUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      //   this.showSnackbar({ text: 'Image Upload Progress', color: 'orange', timeout: 2000 })
    }

    async saveNewCategory() {
      try{
        const valid = await this.$validator.validateAll('createCategory')
        forEach(this.categoryRules, categoryRuleId => {
          this.dataCategoryRulesFinal.push(categoryRuleId.idCategory)
        })
        if (valid){
          // if ((this.$refs as any).imageCategory.getQueuedFiles().length > 0) {
          this.showLoading({ text: 'Saving New Category...'})
          if(this.selectedLevelCategory === 1) {
            if(this.categoryPriority === null){
              this.payload = {
                name: this.categoryName,
                parent_id: null,
                level: 1,
                priority: 0,
                rule: this.dataCategoryRulesFinal
              }
            } else {
              this.payload = {
                name: this.categoryName,
                parent_id: null,
                level: 1,
                priority: this.categoryPriority,
                rule: this.dataCategoryRulesFinal
              }
            }
          } else if (this.selectedLevelCategory === 2) {
            if(this.categoryPriority === null) {
              this.payload = {
                name: this.categoryName,
                parent_id: this.inputTopCategory,
                level: 2,
                priority: 0,
                rule: this.dataCategoryRulesFinal
              }
            } else {
              this.payload = {
                name: this.categoryName,
                parent_id: this.inputTopCategory,
                level: 2,
                priority: this.categoryPriority,
                rule: this.dataCategoryRulesFinal
              }
            }
          } else if (this.selectedLevelCategory === 3){
            if(this.categoryPriority === null){
              this.payload = {
                name: this.categoryName,
                parent_id: this.inputCategoryLevel2,
                level: 3,
                priority: 0,
                rule: this.dataCategoryRulesFinal
              }
            } else {
              this.payload = {
                name: this.categoryName,
                parent_id: this.inputCategoryLevel2,
                level: 3,
                priority: this.categoryPriority,
                rule: this.dataCategoryRulesFinal
              }
            }
          }
          await Categories.createCategories(this.payload)
            .then(response => {
              // Send Images
              if ((this.$refs as any).imageCategory.getQueuedFiles().length > 0) {
                this.newCategoryId = response.data.data.attributes.id
                this.showLoading({ text: 'Uploading Images...' })
                ; (this.$refs as any).imageCategory.setOption('url', `${baseUrl}api/v2/categories/${this.newCategoryId}/image`)
                ; (this.$refs as any).imageCategory.processQueue()
              } else this.done()
            }).catch(error => this.catchHandler(error))
                    
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'teal',
            timeout: 1500
          })
          // this.topCategoriesList = []
          this.cancelAddNewCategoryDialog()
          // } 
          // else {
          //     this.showSnackbar({
          //         text: "Please check all fields requirements",
          //         color: "red",
          //         timeout: 2000
          //     })
          // }
        } else {
          this.showSnackbar({ 
            text: 'Please check all fields requirements', 
            color: 'red', 
            timeout: 1500 
          })
        }
        console.log(this.payload, 'payload')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
        // await this.getCategoryList()
        // await this.getNextCategoriesList(this.inputTopCategoryList, true)
        // await this.getNextCategoriesList(this.inputCategoryLevel2List, false)
      }
    }

    // Delete Category
    deleteCategory(index) {
      this.categoryDeleteModal = true
      this.categoryIdDelete = index
    }

    onCategoryDeletedClose() {
      this.categoryDeleteModal = false
      this.categoryIdDelete = null
    }

    async onCategoryDeletedSave() {
      try {
        this.showLoading({ text: 'Deleting...' })
        const response = await Categories.deleteCategory(this.categoryIdDelete)
        if (response.data.success) {
          this.showSnackbar({
            text: 'Deleted Successfully!',
            color: 'teal',
            timeout: 1500
          })
        } else {
          this.showSnackbar({
            text: 'Delete Failed!',
            color: 'red',
            timeout: 2000
          })
        }
        this.categoryIdDelete = null
        await this.getCategoryList()
        // window.location.reload(true)
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
        this.categoryDeleteModal = false
        this.topCategoriesList = []
        this.categoriesLevel2List = []
        this.categoriesLevel3List = []
        this.dataCategoryRulesFinal = []
        this.categoryRules = []
        await this.getCategoryList()
        await this.getNextCategoriesList(this.inputTopCategoryList, true)
        await this.getNextCategoriesList(this.inputCategoryLevel2List, false)
      }
    }

    // Edit Category
    editCategoryModal: boolean = false
    
    newData: any = {
      name: '',
      parent_id: null,
      inputCategoryLevel2: null,
      level: null,
      priority: null
    }

    oldData: any = {
      name: '',
      parent_id: null,
      level: null,
      priority: null,
      items: []
    }
    selectedForEditCategory: number = null
    
    async editCategory(index) {
      this.categoryRules = []
      this.selectedForEditCategory = index
      this.editCategoryModal = true
      try {
        const opts: any = {
          params: {
            include : 'rules'
          }
        }
        // this.tableLoadingList = true
        const response = await Categories.getSubCategories(index, opts)
        const responseData = response.data.data
        const responseIncluded = response.data.included
            
        if(responseData.attributes.level === 3) {
          const responseLevel2 = await Categories.getSubCategories(responseData.attributes.parent_id, opts)
          const responseDataLevel2 = responseLevel2.data.data

          this.newData = {
            name: responseData.attributes.name,
            parent_id: responseDataLevel2.attributes.parent_id,
            inputCategoryLevel2: responseData.attributes.parent_id,
            level: responseData.attributes.level,
            priority: responseData.attributes.priority
          } 

        } else {
          this.newData = {
            name: responseData.attributes.name,
            parent_id: responseData.attributes.parent_id,
            inputCategoryLevel2: null,
            level: responseData.attributes.level,
            priority: responseData.attributes.priority
          } 
        }
        
        forEach(responseData.relationships.rules, rule => {
          const categoryRule = {
            idCategory: rule.id,
            description: ''
          }
          forEach(responseIncluded, ruleData => {
            categoryRule.description = ruleData[rule.id].attributes.description
          })
                
          this.categoryRules.push(categoryRule)
                
        })
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoadingList = false
      }
    }

    async saveEditCategory(){
      try{
        const valid = await this.$validator.validateAll('editCategory')
        forEach(this.categoryRules, categoryRuleId => {
          this.dataCategoryRulesFinal.push(categoryRuleId.idCategory)
        })
        if (valid && !this.noLevel2Categories){
                
          if(this.newData.level === 1) {
            this.payload = {
              name: this.newData.name,
              parent_id: null,
              level: 1,
              priority: this.newData.priority,
              rule: this.dataCategoryRulesFinal
            }
          } else if (this.newData.level === 2) {
            this.payload = {
              name: this.newData.name,
              parent_id: this.newData.parent_id,
              level: 2,
              priority: this.newData.priority,
              rule: this.dataCategoryRulesFinal
            }
          } else {
            this.payload = {
              name: this.newData.name,
              parent_id: this.newData.inputCategoryLevel2,
              level: 3,
              priority: this.newData.priority,
              rule: this.dataCategoryRulesFinal
            }
          }
                
          // console.log(this.payload, 'payload')
          this.showLoading({ text: 'Saving Edit Category...'})
          // this.cancelModal()
          const response = await Categories.editCategories(this.payload, this.selectedForEditCategory)

          if(response) {
            this.showSnackbar({
              text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' +response.status),
              color: response.status === 200 ? 'green' : 'red',
              timeout: 1500
            })
            this.cancelEditCategoryDialog()
            this.closeLoading()
            // window.location.reload(true)
            this.topCategoriesList = []
            this.categoriesLevel2List = []
            this.categoriesLevel3List = []
            this.dataCategoryRulesFinal = []
            this.categoryRules = []
            await this.getCategoryList()
            await this.getNextCategoriesList(this.inputTopCategoryList, true)
            await this.getNextCategoriesList(this.inputCategoryLevel2List, false)
          }
        } 
        else {
          if(this.noLevel2Categories) {
            this.showSnackbar({ text: 'Please choose other top category!', color: 'red', timeout: 1500 })
          } else {
            this.showSnackbar({ text: 'Please check all fields requirements', color: 'red', timeout: 1500 })
          }
                
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {

      }
    }

    // Input Category Image
    modalInputImageCategory: boolean = false
    idInputImageCategory: number = null

    inputImageCategory(data) {
      if(data.item.level === 1) {
        this.idInputImageCategory = data.item.categoryId
      } else {
        this.idInputImageCategory = data.item.value
      }
      this.modalInputImageCategory = true
    }

    cancelInputImageCategory() {
      this.modalInputImageCategory = false
      ; (this.$refs as any).imageCategory.removeFile(this.fileImages)
    }

    async saveInputImageCategory() {
      if ((this.$refs as any).imageCategory.getQueuedFiles().length > 0) {
        this.newCategoryId = this.idInputImageCategory
        this.showLoading({ text: 'Uploading Images...' })
        ; (this.$refs as any).imageCategory.setOption('url', `${baseUrl}api/v2/categories/${this.idInputImageCategory}/image`)
        ; (this.$refs as any).imageCategory.processQueue()   
      } else this.done()
    }

    cancelEditCategoryDialog() {
      this.newData = []
      this.editCategoryModal = false
    }

    // Get Category in Form Create Category (Add New Category)
    inputTopCategory: number = null
    inputCategoryLevel2: number = null
    
    async getCategories() {
      try {
        const responseCategories = await ProductService.getTopLevelCategories(this.opts)
        forEach(responseCategories.data.data, category => {
          const dataCategoryMap: any = {
            text: category.attributes.name,
            value: category.attributes.id
          }
          this.dataTopCategory.items.push(dataCategoryMap)
        })
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoadingList = false
      }
    }

    async getNextCategories(categoryId, inputTopCategory = false) {
      this.dataCategoryLevel2.items = []
      const opts: any = {
        params: {
          'page[limit]' : '100'
        }
      }
      try {
        let parentId = categoryId

        if (inputTopCategory) {
          this.loadingLevel2Categories = true
          this.noLevel2Categories = false
          this.noLevel3Categories = false
          this.categoriesLevel2 = []
          this.categoriesLevel3 = []
        } else {
          this.loadingLevel3Categories = true
          this.noLevel3Categories = false
          this.categoriesLevel3 = []
        }
    
        const responseData = await ProductService.getLowLevelCategories(parentId, opts)
    
        if (responseData.data.data.length > 0) {
          //assign response data to global object
          forEach(responseData.data.data, category => {
            const dataCategoryMap: any = {
              text: category.attributes.name,
              value: category.attributes.id
            }
            if (inputTopCategory) {
              this.dataCategoryLevel2.items.push(dataCategoryMap)
              this.loadingLevel2Categories = false
            } else {
                        
              this.categoriesLevel3.push(dataCategoryMap)
              this.loadingLevel3Categories = false
            }
          })
        } else {
          //No category dimmers if no data
          if (inputTopCategory) {
            this.loadingLevel2Categories = false
            this.noLevel2Categories = true
          } else {
            this.loadingLevel3Categories = false
            this.noLevel3Categories = true
          }
        }
      } catch (error) {
        this.catchHandler(error)
      }
    }

    // Get Category Rules
    dataCategoryRules: any = {
      items: []
    }

    async getCategoryRules() {
      try {
        const opts: any = {
          params: {
            'page[limit]' : '100000'
          }
        }
        const response = await Rules.getCategoryRules(opts)
        forEach(response.data.data, categoryRule => {
          const dataCategoryRule: any = {
            text: categoryRule.attributes.description,
            value: categoryRule.attributes.id
          }
          this.dataCategoryRules.items.push(dataCategoryRule)
        })
      } catch (error) {
        this.catchHandler(error)
      } finally {
        // this.products.loading = false
      }
    }
    
    addCategoryRule() {
      const categoryRule = {
        idCategory: null,
        description: ''
      }
      this.categoryRules.push(categoryRule)
    }
    
    removeCategoryRule(index) {
      this.categoryRules.splice(index, 1)
    }
    
    async done() {
      this.showSnackbar({ text: 'Image Successfully Added', color: 'green', timeout: 2000 })
      this.closeLoading()
      this.modalInputImageCategory = false
      this.cancelAddNewCategoryDialog()
      this.topCategoriesList = []
      this.categoriesLevel2List = []
      this.categoriesLevel3List = []
      this.dataCategoryRulesFinal = []
      this.categoryRules = []
      await this.getCategoryList()
      await this.getNextCategoriesList(this.inputTopCategoryList, true)
      await this.getNextCategoriesList(this.inputCategoryLevel2List, false)
    }
    
    error() {
      // this.loadingShow = false
      this.showSnackbar({
        text: 'Error! Something Happened. Check your internet connections',
        color: 'red',
        timeout: 4000
      })
    }
}
